<template>
  <div class="front-cont">
    <div
      v-if="!searched"
      class="img-bg row"
      :style="{ 'background-image': $h.getWideBg() }"
      style="opacity: 0.95"
    >
      <div class="f-search-content">
        <div class="p-2 p-md-4 row bg-white search-box">
          <div class="col-md-3 d-flex align-items-center">
            <h1 class="section-title f-text-blue mb-0 mt-2 mt-md-0">
              {{ $t.translate("FP_SEARCH") }}
            </h1>
          </div>

          <div class="col-md-9 mb-3 mb-md-0 mt-2">
            <div class="d-flex align-items-center mb-3">
              <el-input v-model="formData.keywords" class="search-input"></el-input>

              <div class="ml-3 c-pointer" @click="searchNow">
                <img
                  src="/img/front/search.png"
                  style="width: 35px; height: 35px; margin-top: 2px"
                />
              </div>
            </div>

            <div class="d-flex">
              <h4 class="mr-3 mb-0 f-text-gray" style="min-width: 62px; line-height: 1.1">
                {{ $t.translate("LBL_FILTER_BY") }}
              </h4>
              <el-radio-group v-model="formData.filter" class="filter-text">
                <el-radio
                  v-for="option in TypeOptions"
                  :key="option.key"
                  :label="option.key"
                >
                  {{ $t.translate(option.label) }}
                </el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="row">
      <div class="col-md-12 f-search-list f-list-bg pt-5 pt-md-0">
        <div class="px-2 px-md-7 py-4">
          <div class="px-1 px-md-4 py-2 bg-white row s-item-card">
            <div class="col-md-6 col-lg-6 col-xl-4 my-2 my-md-0">
              <div class="d-flex align-items-center">
                <h3 class="mb-0 mr-2 f-text-gray">{{ $t.translate("FP_SEARCH") }}</h3>

                <el-input
                  v-model="formData.keywords"
                  class="search-input small"
                ></el-input>

                <div class="ml-2 c-pointer mr-0 mr-md-1" @click="searchNow">
                  <img
                    src="/img/front/search.png"
                    style="width: 29px; height: 29px; margin-top: 1px"
                  />
                </div>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-6 col-xl-4 d-flex align-items-center justify-content-md-center middle-border"
            >
              <h4 class="mb-0 f-text-gray">{{ $t.translate("LBL_FILTER_BY") }}</h4>
              <h4
                v-for="option in TypeOptions"
                :key="option.key"
                @click="formData.filter = option.key"
                class="f-text-gray mb-0 ml-3 c-pointer"
                :class="{ 'f-text-blue': formData.filter == option.key }"
              >
                {{ $t.translate(option.label) }}
              </h4>
            </div>

            <div
              class="col-md-6 col-lg-6 col-xl-4 d-flex align-items-center my-2 my-md-0"
            >
              <h4 class="ml-0 ml-md-1 mr-1 mb-0 f-text-gray">
                {{ $t.translate("LBL_SHOWING_FROM") }}
              </h4>

              <div
                v-for="option in EntriesOpts"
                :key="option.key"
                @click="Pagination.PageSize = option.key"
                class="entry-box c-pointer mr-1"
                :class="{ 'is-active': Pagination.PageSize == option.key }"
              >
                <h4
                  class="f-text-gray mb-0"
                  :class="{ 'f-text-blue': Pagination.PageSize == option.key }"
                >
                  {{ option.label }}
                </h4>
              </div>

              <h4 class="mr-1 mb-0 f-text-gray">
                {{
                  $t
                    .translate("LBL_OF_RESULTS")
                    .replace("{LISTSIZE}", Pagination.PageSize)
                }}
              </h4>
            </div>
          </div>

          <div
            v-for="item in Pagination.Items"
            :key="item.ServiceKey"
            class="px-1 px-md-4 py-3 bg-white row mt-3 s-item-card c-pointer"
            @click="viewService(item)"
          >
            <div class="col-md-12">
              <h2 class="text-dark mb-2">
                {{ $h.formatName(item, "Title") }}
              </h2>

              <h4 class="f-text-orange mb-2">
                <span class="mr-3">{{ $t.translate(item.Type) }}</span
                ><span
                  v-for="(tag, tagIndex) in item.Tags"
                  :key="'tag-' + tagIndex"
                  class="f-text-gray mr-1"
                  >#{{ $t.translate(tag) }}</span
                >
              </h4>

              <p class="one-line-text text-dark">
                {{ $h.formatName(item, "Description") }}
              </p>
            </div>
          </div>

          <div
            v-if="!Pagination.Items.length"
            class="px-1 px-md-4 py-3 bg-white row mt-3"
          >
            <div class="col-md-12">
              <p class="f-text-gray mb-2">{{ $t.translate("LBL_NO_DATA") }}</p>
            </div>
          </div>

          <div v-if="Pagination.Items.length" class="mt-3 mx--3">
            <el-pagination
              v-model:current-page="Pagination.CurrentPage"
              layout="prev, pager, next"
              :total="Pagination.ItemsTotal"
              :pager-count="5"
              :page-size="Pagination.PageSize"
              @current-change="loadItems"
              class="f-gen-pagination"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, onUpdated, ref, reactive, computed } from "vue";
import { useRoute } from "vue-router";
import { post, get, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { router } from "@/utils/router";

export default {
  setup() {
    const route = useRoute();
    let searched = ref(false);
    let formData = reactive({
      keywords: null,
      filter: null,
    });
    const TypeOptions = [
      { key: "ALL", label: "OPT_ALL_RESULTS" },
      { key: "NEWS", label: "OPT_NEWS" },
      { key: "SERVICE", label: "OPT_SERVICE" },
      { key: "RESOURCES", label: "OPT_RESOURCES" },
    ];
    const EntriesOpts = [
      { key: 10, label: 10 },
      { key: 25, label: 25 },
      { key: 50, label: 50 },
      { key: 100, label: 100 },
    ];
    let Pagination = reactive({
      Items: [],
      ItemsTotal: 0,
      PageSize: 10,
      CurrentPage: 1,
    });

    onMounted(async () => {});

    onUpdated(() => {});

    const loadItems = async () => {
      Pagination.ItemsTotal = 90;

      let tmpList = [
        {
          ServiceKey: "AOS23-1233434",
          Type: "Assessment",
          Tags: ["ESG", "Climate"],
          Title: "HKQAA Net-Zero Certificate Scheme",
          TitleCHT: "Dummy Title CHT",
          TitleCHS: "Dummy Title CHS",
          Logo: "/img/front/service1.png",
          DescriptionCHT: "Desc CHT xxx xxx",
          DescriptionCHS: "Desc CHS xxx xxx",
          Description:
            "Which rule fly first void made bring fruitful above appear that. Replenish bearing him them fill all green which above every have every don't in whose second a divided. Doesn't appear fruitful make moving set set firmament sea dry so winged. Whales divided land thing shall, deep abundantly darkness. Abundantly. Of don't greater own face deep darkness dry earth replenish dominion good, darkness seas forth. Gathered dry give called all herb fifth there gathering. Created said us night she'd. Was yielding itself years man they're moving shall seasons let which whales in. Yielding i man face. Fill behold Female were.",
        },
        {
          ServiceKey: "AOS23-1204564",
          Type: "Assessment",
          Tags: ["ESG", "Climate"],
          Title: "Green and Sustainable Finance Certification Scheme",
          TitleCHT: "Dummy Title CHT",
          TitleCHS: "Dummy Title CHS",
          Logo: "/img/front/service2.png",
          DescriptionCHT: "Desc CHT xxx xxx",
          DescriptionCHS: "Desc CHS xxx xxx",
          Description:
            "Which rule fly first void made bring fruitful above appear that. Replenish bearing him them fill all green which above every have every don't in whose second a divided. Doesn't appear fruitful make moving set set firmament sea dry so winged. Whales divided land thing shall, deep abundantly darkness. Abundantly. Of don't greater own face deep darkness dry earth replenish dominion good, darkness seas forth. Gathered dry give called all herb fifth there gathering. Created said us night she'd. Was yielding itself years man they're moving shall seasons let which whales in. Yielding i man face. Fill behold Female were.",
        },
        {
          ServiceKey: "AOS23-204456",
          Type: "Assessment",
          Tags: ["ESG", "Climate"],
          Title: "Second Party Opinion",
          TitleCHT: "Dummy Title CHT",
          TitleCHS: "Dummy Title CHS",
          Logo: "/img/front/service1.png",
          DescriptionCHT: "Desc CHT xxx xxx",
          DescriptionCHS: "Desc CHS xxx xxx",
          Description:
            "Which rule fly first void made bring fruitful above appear that. Replenish bearing him them fill all green which above every have every don't in whose second a divided. Doesn't appear fruitful make moving set set firmament sea dry so winged. Whales divided land thing shall, deep abundantly darkness. Abundantly. Of don't greater own face deep darkness dry earth replenish dominion good, darkness seas forth. Gathered dry give called all herb fifth there gathering. Created said us night she'd. Was yielding itself years man they're moving shall seasons let which whales in. Yielding i man face. Fill behold Female were.",
        },
        {
          ServiceKey: "AOS23-4852933",
          Type: "Assessment",
          Tags: ["ESG", "Climate"],
          Title: "TCFD Report Verification",
          TitleCHT: "Dummy Title CHT",
          TitleCHS: "Dummy Title CHS",
          Logo: "/img/front/service2.png",
          DescriptionCHT: "Desc CHT xxx xxx",
          DescriptionCHS: "Desc CHS xxx xxx",
          Description:
            "Which rule fly first void made bring fruitful above appear that. Replenish bearing him them fill all green which above every have every don't in whose second a divided. Doesn't appear fruitful make moving set set firmament sea dry so winged. Whales divided land thing shall, deep abundantly darkness. Abundantly. Of don't greater own face deep darkness dry earth replenish dominion good, darkness seas forth. Gathered dry give called all herb fifth there gathering. Created said us night she'd. Was yielding itself years man they're moving shall seasons let which whales in. Yielding i man face. Fill behold Female were.",
        },
        {
          ServiceKey: "AOS23-2348",
          Type: "Assessment",
          Tags: ["ESG", "Climate"],
          Title: "Second Party Opinion",
          TitleCHT: "Dummy Title CHT",
          TitleCHS: "Dummy Title CHS",
          Logo: "/img/front/service1.png",
          DescriptionCHT: "Desc CHT xxx xxx",
          DescriptionCHS: "Desc CHS xxx xxx",
          Description:
            "Which rule fly first void made bring fruitful above appear that. Replenish bearing him them fill all green which above every have every don't in whose second a divided. Doesn't appear fruitful make moving set set firmament sea dry so winged. Whales divided land thing shall, deep abundantly darkness. Abundantly. Of don't greater own face deep darkness dry earth replenish dominion good, darkness seas forth. Gathered dry give called all herb fifth there gathering. Created said us night she'd. Was yielding itself years man they're moving shall seasons let which whales in. Yielding i man face. Fill behold Female were.",
        },
        {
          ServiceKey: "AOS23-457878",
          Type: "Assessment",
          Tags: ["ESG", "Climate"],
          Title: "TCFD Report Verification",
          TitleCHT: "Dummy Title CHT",
          TitleCHS: "Dummy Title CHS",
          Logo: "/img/front/service2.png",
          DescriptionCHT: "Desc CHT xxx xxx",
          DescriptionCHS: "Desc CHS xxx xxx",
          Description:
            "Which rule fly first void made bring fruitful above appear that. Replenish bearing him them fill all green which above every have every don't in whose second a divided. Doesn't appear fruitful make moving set set firmament sea dry so winged. Whales divided land thing shall, deep abundantly darkness. Abundantly. Of don't greater own face deep darkness dry earth replenish dominion good, darkness seas forth. Gathered dry give called all herb fifth there gathering. Created said us night she'd. Was yielding itself years man they're moving shall seasons let which whales in. Yielding i man face. Fill behold Female were.",
        },
      ];

      Pagination.Items = tmpList;

      searched.value = true;
    };

    const searchNow = async () => {
      searched.value = false;
      loadItems();
    };

    const viewService = async (row) => {
      router.changeRoute("/service/view/" + row.ServiceKey);
    };

    return {
      searched,
      formData,
      TypeOptions,
      EntriesOpts,
      Pagination,
      loadItems,
      searchNow,
      viewService,
    };
  },
};
</script>
<style>
.f-search-content {
  /* position: absolute; */
  width: 100%;
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-bg {
  background-size: cover;
  min-height: 75vh;
}
.search-box {
  border-radius: 8px;
  width: 800px;
  opacity: 0.92;
}
.front-cont .filter-text * {
  font-family: AcuminConcept !important;
  color: #707070;
}
.front-cont .filter-text .el-radio__label {
  line-height: 1;
  font-size: var(--f-size-radio-label);
}
.f-search-list {
  width: 100% !important;
}
.f-search-list .entry-box {
  border: 1px solid #707070;
  border-radius: 4px;
  padding: 0px 3px;
}
.f-search-list .entry-box.is-active {
  border: 1px solid #1673ff;
}
.front-cont .search-input.small .el-input__inner {
  height: 30px;
}
.middle-border {
  border-left: 1.5px solid #dfdddd;
  border-right: 1.5px solid #dfdddd;
}
.s-item-card {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.f-search-list .el-pagination .el-pager .number.active {
  background: #1673ff;
  color: #fff;
}

@media (max-width: 900px) {
  .search-box {
    width: 88%;
  }
  .middle-border {
    border-left: 0px solid #dfdddd;
    border-right: 0px solid #dfdddd;
  }
}
</style>
